import { useState } from 'react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import axiosInstance from 'utilities/requestUtillity';

export interface AxiosState<T> {
  loading: boolean | null;
  error: unknown;
  data: T | null;
  status: number;
}

// req methods
export enum MethodTypes {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

const defaultAxiosInstance = axios.create(); // A clean Axios instance

type CallAxiosFn = (config: AxiosRequestConfig, useCustomInstance?: boolean) => Promise<void>;
type ResetState = () => void;
type UseAxios = <T>() => [CallAxiosFn, AxiosState<T>, ResetState]; // note the T. This allows us to send in back a response of a specific type.
const useAxios: UseAxios = <T>() => {
  const [axiosState, setAxiosState] = useState<AxiosState<T>>({
    loading: null,
    error: null,
    data: null,
    status: 0,
  });

  const resetState = () => {
    setAxiosState({ loading: null, error: null, data: null, status: 0 });
  };

  const callAxiosFn: CallAxiosFn = async (config, useCustomInstance = true) => {
    const instance = useCustomInstance ? axiosInstance : defaultAxiosInstance;
    // state on loading
    setAxiosState({
      loading: true,
      error: null,
      data: null,
      status: 0,
    });

    try {
      const response = await instance({
        headers: {
          // Accept: 'application/json',
          ...(config.headers || {}),
        },
        ...config,
      });

      // call refresh and call the same token again.

      // state on success
      setAxiosState({
        loading: false,
        error: null,
        data: response.data,
        status: response.status,
      });
    } catch (e) {
      const axiosError = e as AxiosError;
      console.log('The e', e);
      // state on error
      setAxiosState({
        loading: false,
        error: e,
        data: null,
        status: axiosError.response.status,
      });
    }
  };
  return [callAxiosFn, axiosState, resetState];
};

export const useMultiAxios = () => {
  const useAxiosWrapper = async <T>(config: AxiosRequestConfig): Promise<AxiosState<T>> => {
    const [callAxiosFn, axiosState] = useAxios<T>();
    await callAxiosFn(config); // Trigger the API call
    return axiosState; // Return the state of this specific call
  };

  return useAxiosWrapper;
};

export default useAxios;
