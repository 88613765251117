import Bicep from 'assets/images/Bicep.png';
import Tricep from 'assets/images/Tricep.png';
import Abs from 'assets/images/Abs.png';
import { TrainingType } from 'pages/Upload';
import useAxios, { AxiosState } from 'hooks/useAxios';
import { AxiosRequestConfig } from 'axios';

export const getBackgroundImage = (type) => {
  switch (type) {
    case TrainingType.Bicep:
      return Bicep; // Import or define this variable earlier
    case TrainingType.Tricep:
      return Tricep;
    case TrainingType.Abs:
      return Abs;
    default:
      return ''; // Optional: a default image
  }
};
