import styled from 'styled-components';
import { MediaProps } from './index';
import AspectRatio, { Ratio } from 'Shared/AspectRatio';
import { useCallback, useEffect } from 'react';
import { UrlViewModel } from 'utilities/breakpoints';
import { useRef, useState } from 'react';
import { TrainingType } from 'pages/Upload';
import { Play, XFeatther } from 'Shared/icon';
import { getBackgroundImage } from 'utilities/fetcher';
import { Cover, Tags, TagsContainer, VideoContainer } from 'Shared/Components';
import { LightButton } from 'Shared/Components/Button';
import { canvasToFile, formatTime } from 'utilities';
type Props = {
  src: UrlViewModel;
  fileName: string;
  trainingType: TrainingType;
  isEdit: boolean;
  isHovered: boolean;
  onScreenShotUpdate: (screenFile: File) => void;
};

const VideoPreview: React.FC<Props> = (props) => {
  const { src, trainingType, isEdit, isHovered, fileName, onScreenShotUpdate } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const [screenshotSrc, setScreenshotSrc] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState(
    getBackgroundImage(trainingType ?? TrainingType.Bicep)
  );
  const [fade, setFade] = useState(true);

  const [generateScreenShot, setGenerateScreneshot] = useState<boolean>(false);
  const [videoDuration, setVideoDuration] = useState<number>(0);

  const togglePlay = useCallback(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying((prev) => !prev);
    }
  }, [isPlaying]);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleRangeChange = (event) => {
    videoRef.current.currentTime = event.target.value;
  };

  const toggleVideo = useCallback(() => {
    setPlayVideo((prev) => !prev);
  }, []);

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setVideoDuration(videoRef.current.duration);
    }
  };

  const takeScreenShot = async () => {
    if (!videoRef.current || videoRef.current.readyState < HTMLMediaElement.HAVE_CURRENT_DATA)
      return;

    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

    var myscreen = await canvasToFile(canvas);
    onScreenShotUpdate(myscreen);
    const imgData = canvas.toDataURL('image/png');

    setScreenshotSrc(imgData);
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  useEffect(() => {
    if (screenshotSrc) {
      setBackgroundImage(screenshotSrc);
      handleToggle();
    }
  }, [screenshotSrc]);

  useEffect(() => {
    if (playVideo && videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    } else if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, [playVideo]);

  useEffect(() => {
    if (!screenshotSrc) {
      setFade(false); // Start fade-out
      const timeout = setTimeout(() => {
        setBackgroundImage(getBackgroundImage(trainingType ?? TrainingType.Bicep)); // Update the image
        setFade(true); // Start fade-in
      }, 500); // Match this to the CSS transition duration

      return () => clearTimeout(timeout);
    }
  }, [trainingType, screenshotSrc]);

  useEffect(() => {
    if (!isEdit) {
      setPlayVideo(false);
      setIsPlaying(false);
      setGenerateScreneshot(false);
      if (!screenshotSrc) setScreenshotSrc(null);
    }
  }, [isEdit, screenshotSrc]);

  const handleToggle = () => {
    toggleVideo();
    setGenerateScreneshot(!generateScreenShot);
  };

  const removeScreen = () => {
    setGenerateScreneshot(false);
    setScreenshotSrc(null);
    onScreenShotUpdate(null);
    // file.screenshot = null;
  };

  return (
    <>
      {generateScreenShot && (
        <VideoContainer isEdit={isEdit}>
          <Video
            isScreenshot
            ref={videoRef}
            src={src.url}
            muted={true}
            onLoadedMetadata={handleLoadedMetadata}
            onTimeUpdate={handleTimeUpdate} // React event prop for time updates
          />
          <RangeControls>
            <StyledRangeInput
              onChange={handleRangeChange}
              min="0"
              max={videoDuration}
              step="0.1"
              value={currentTime}
            />
            <TimeDisplay>
              {formatTime(currentTime)} / {formatTime(videoDuration)}
            </TimeDisplay>
          </RangeControls>
        </VideoContainer>
      )}
      {playVideo && !generateScreenShot ? (
        <VideoContainer isEdit={isEdit}>
          <Video
            ref={videoRef}
            src={src.url}
            muted={isMuted}
            onLoadedMetadata={handleLoadedMetadata}
            onTimeUpdate={handleTimeUpdate}
            loop
            playsInline
          />
          <StopButton isHoverinteraction={isHovered && !isEdit} onClick={toggleVideo}>
            <CloseIcon />
          </StopButton>
          {!generateScreenShot && (
            <>
              <RangeControls>
                <StyledRangeInput
                  onChange={handleRangeChange}
                  min="0"
                  max={videoDuration}
                  step="0.1"
                  value={currentTime}
                />
                <TimeDisplay>
                  {formatTime(currentTime)} / {formatTime(videoDuration)}
                </TimeDisplay>
              </RangeControls>
              <Controls>
                <ControlButton onClick={togglePlay}>
                  <Icon>{isPlaying ? '⏸️' : '▶️'}</Icon>
                  {isPlaying ? 'Pause' : 'Play'}
                </ControlButton>
                <ControlButton onClick={toggleMute}>
                  <Icon>{isMuted ? '🔇' : '🔊'}</Icon>
                  {isMuted ? 'Unmute' : 'Mute'}
                </ControlButton>
              </Controls>
            </>
          )}
        </VideoContainer>
      ) : (
        !generateScreenShot && (
          <Cover isEdit={isEdit} backgroundImage={backgroundImage} fade={fade}>
            <PlayButton onClick={toggleVideo}>
              <PlayIcon />
            </PlayButton>
            {!isEdit && (
              <TagsContainer>
                <Tags>{fileName}</Tags>
                <Tags>{trainingType}</Tags>
              </TagsContainer>
            )}
          </Cover>
        )
      )}

      {isEdit && (
        <ImageSelection>
          {generateScreenShot && (
            <ScreenShotButton onClick={takeScreenShot}>Screenshot</ScreenShotButton>
          )}
          <Label>
            {screenshotSrc
              ? 'remove screenshot'
              : generateScreenShot
              ? 'Pull the slider and press screenshot'
              : 'Generate image from Video?'}
          </Label>
          {screenshotSrc ? (
            <Slider isChecked={false} onClick={removeScreen}>
              <Knob isChecked={false} />
            </Slider>
          ) : (
            <Slider isChecked={generateScreenShot} onClick={handleToggle}>
              <Knob isChecked={generateScreenShot} />
            </Slider>
          )}
        </ImageSelection>
      )}
    </>
  );
};

export default VideoPreview;

const Label = styled.span`
  font-size: 1rem;
  color: #333;
`;

const Video = styled.video<{ isScreenshot?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${({ isScreenshot }) =>
    isScreenshot
      ? 'border-top-left-radius:18px; border-top-right-radius:18px;'
      : 'border-radius: 18px;'}
`;

const Controls = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  opacity: 0;
  transition: opacity 0.3s ease;
  ${VideoContainer}:hover & {
    opacity: 1; /* Show controls on hover */
  }
`;

const RangeControls = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0px;
  display: flex;
  align-items: center;
  gap: 15px;
  opacity: 1;
  transition: opacity 0.3s ease, bottom 0.4s ease;
  ${VideoContainer}:hover & {
    bottom: 59px;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ControlButton = styled.button`
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  color: #333;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  &:active {
    transform: scale(0.95); /* Add a small scale effect on click */
  }
`;

// Optional icon style for button icons
const Icon = styled.span`
  font-size: 14px;
  margin-right: 5px;
`;

const PlayButton = styled.button`
  border: none;
  background-color: rgba(255, 255, 255, 0);
  color: rgb(248, 230, 211);
`;

const StopButton = styled.button<{ isHoverinteraction: boolean }>`
  position: absolute;
  top: ${({ isHoverinteraction }) => (isHoverinteraction ? '50px;' : '5px;')};
  right: 5px;
  background-color: white;
  color: black;
  border-radius: 100vw;
  box-shadow: rgba(0, 0, 0, 0.21) 5px 5px 20px;
  border: none;
`;

const PlayIcon = styled(Play)`
  font-size: 60px;
`;

const CloseIcon = styled(XFeatther)`
  font-size: 12px;
`;

const ImageSelection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

// Styled Components
const TimeDisplay = styled.div`
  font-size: 14px;
  color: white;
  font-weight: bold;
  width: 100%;
  text-align: left;
  padding-left: 5px;
`;

const StyledRangeInput = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none;
  width: 100%;
  height: 30px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: red;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: red;
    cursor: pointer;
  }

  &::-webkit-slider-runnable-track {
    background: lightblue;
    border-radius: 5px;
  }

  &::-moz-range-track {
    background: lightblue;
    border-radius: 5px;
  }
`;

const Slider = styled.div<{ isChecked: boolean }>`
  width: 50px;
  height: 25px;
  background-color: ${({ isChecked }) => (isChecked ? '#4cd964' : '#ccc')};
  border-radius: 34px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  padding: 0 2px;
`;

const Knob = styled.div<{ isChecked: boolean }>`
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
  transform: ${({ isChecked }) => (isChecked ? 'translateX(24px)' : 'translateX(0)')};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const ScreenShotButton = styled(LightButton)`
  margin-top: 5px;
`;
