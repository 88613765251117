import { EditFeattherTwo, TrashFeatther } from 'Shared/icon';
import styled, { keyframes } from 'styled-components';

const animLoaderSpiner = keyframes`
  0% {
    border-color: white rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  }
  33% {
    border-color: white white rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  }
  66% {
    border-color: white white white rgba(255, 255, 255, 0);
  }
  100% {
    border-color: white white white white;
  }
`;

const animationProgressbar = keyframes`
  to {
    width: 100%;
  }
`;

export const Loader = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 10px solid;
  box-sizing: border-box;
  animation: ${animLoaderSpiner} 1s linear infinite alternate;
`;

export const ProgressLoader = styled.div`
  display: block;
  position: relative;
  height: 25px;
  width: 200px;
  background: #fff;
  overflow: hidden;

  // Define the inner bar using a pseudo-element
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background: #ff3d00;
    animation: ${animationProgressbar} 6s ease-in infinite;
  }
`;

export const Overlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 9997; /* Behind the Nav component */
`;

export const PreviewContainer = styled.div<{ isEdit: boolean }>`
  display:flex;
  border 1px solid black;
  border-radius:20px;
  width:${({ theme }) => (theme.isTablet ? '47%' : theme.isCompact ? `90%` : '20%')};
  text-align:center;
  flex-direction:column;
  margin:5px;
  align-items:center;
  background-color:white;
  position:relative;
  height: ${({ isEdit }) => (isEdit ? 'auto' : '350px')};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export const PersonImageContainer = styled(ImageContainer)`
  background: linear-gradient(to right, #d81d73, #a022ff);
  padding: 20px 0px;
`;

export const Image = styled.img`
  width: 100%;
  height: 190px;
  max-height: 300px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export const PersonImage = styled.img`
  width: 50%;
  height: 150px;
  max-height: 300px;
  border-radius: 100%;
  border: 2px solid white;
`;

export const Text = styled.p`
  color: black;
  font-weight: bolder;
  background-color: white;
  border-radius: 20px;
  padding: 5px;
  width: fit-content;
`;

export const Label = styled.label`
  font-weight: 750;
`;

export const Dropdown = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

export const DropdownItem = styled.option`
  padding: 8px;
`;

export const Edit = styled(EditFeattherTwo)`
  color: white;
  font-size: 15px;
  &:hover {
    color: orange;
  }
`;

export const Remove = styled(TrashFeatther)`
  color: white;
  font-size: 15px;
  &:hover {
    color: red;
  }
`;

export const VideoContainer = styled.div<{ isEdit: boolean }>`
  position: relative;
  width: 100%;
  min-height: 190px;
  height: ${({ isEdit }) => (!isEdit ? '100%;' : 'auto;')}
  background-color: white;
  padding: 20px;
  border-radius:20px;
`;

export const Cover = styled.div<{ backgroundImage: string; fade: boolean; isEdit: boolean }>`
  position: relative;
  width: 100%;
  min-height: 190px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: ${({ isEdit }) => (!isEdit ? '100%;' : 'auto;')}
  border-radius: ${({ isEdit }) => (!isEdit ? '20px;' : 'initial;')}

  // Single pseudo-element for fade effect
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease-in-out;
    opacity: ${({ fade }) => (fade ? 1 : 0)};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 0;
  }

  // Ensure content appears above the fading background
  & > * {
    position: relative;
    z-index: 1;
  }
`;

export const TagsContainer = styled.div`
  position: absolute;
  bottom: 10px; /* Adjust for desired padding from the bottom */
  left: 5px;
  text-align: center; /* Center-align text within the full width */
  margin: 0; /* Remove default paragraph margin */
  z-index: 1; /* Ensure it appears above the background */
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Tags = styled(Text)`
  min-width: 30%;
`;
