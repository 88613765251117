import { v4 as uuidv4 } from 'uuid';
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
export const makeArray = (obj: any) => Array.from(Object.keys(obj), (k) => [`${k}`, obj[k]]);

export const checkIfArrayHasValue = (array: Array<Object>): boolean => {
  let hasValue = false;
  // eslint-disable-next-line array-callback-return
  array.map((prop: any): void => {
    if (prop[1] !== '') hasValue = true;
  });

  return hasValue;
};

export const MakeChunkArray = (array: Array<any>, number: number, prop?: any) => {
  if (!array.length) {
    return [];
  }

  if (prop)
    return array
      .sort((a, b) => a[prop] - b[prop])
      .slice(0, ((array.length + number - 1) / number) | 0)
      .map((c, i) => array.slice(number * i, number * i + number));

  return array
    .slice(0, ((array.length + number - 1) / number) | 0)
    .map((c, i) => array.slice(number * i, number * i + number));
};

export const validateField = <T extends {}>(fieldName: string, value: string): T => {
  let errors = {} as unknown;

  switch (fieldName) {
    case 'text':
      errors = {
        [fieldName]: value.trim() === '' ? 'please eneter a value' : '',
      };
      break;
    default:
      errors = {
        [fieldName]: value.trim() === '' ? 'please eneter a value' : '',
      };
      break;
  }

  return errors as T;
};

export const generateScreenshots = async (videoFiles: Array<File>): Promise<string[]> => {
  return Promise.all(
    videoFiles.map((file) => {
      return new Promise<string>((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.src = URL.createObjectURL(file);

        // When video metadata is loaded, seek to 3 seconds
        video.onloadedmetadata = () => {
          video.currentTime = 3;
        };

        // When video has seeked to the time we've set
        video.onseeked = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          const ctx = canvas.getContext('2d');
          if (!ctx) {
            reject(new Error('Failed to get canvas context'));
            return;
          }

          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          // You can also convert the canvas to a blob and then use URL.createObjectURL(blob) for creating an object URL.
          const screenshot = canvas.toDataURL('image/jpeg');

          resolve(screenshot);

          // Clean up memory by revoking the object URL and releasing any internal reference the video might hold
          URL.revokeObjectURL(video.src);
          video.src = '';
        };

        // Error handling for the video loading process
        video.onerror = (err) => {
          reject(err);
        };
      });
    })
  );
};

export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(time % 60)
    .toString()
    .padStart(2, '0');
  return `${minutes}:${seconds}`;
};

export const canvasToFile = async (canvas, fileName = 'screenshot.png'): Promise<File> => {
  const guidId = uuidv4();
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], fileName + guidId, { type: 'image/png' });
        resolve(file);
      } else {
        reject(new Error('Canvas is empty or could not be converted to Blob'));
      }
    }, 'image/png');
  });
};

export enum FileType {
  Image = 'Image',
  Video = 'Video',
  Unsupported = 'Unsupported',
}

export enum ImageExtension {
  JPG = 'JPG',
  JPEG = 'JPEG',
  PNG = 'PNG',
  GIF = 'GIF',
  BMP = 'BMP',
  TIFF = 'TIFF',
  TIF = 'TIF',
  WEBP = 'WEBP',
  PSD = 'PSD',
  AI = 'AI',
  ICO = 'ICO',
  HEIC = 'HEIC',
  HEIF = 'HEIF',
  RAW = 'RAW',
  INDD = 'INDD',
  SVG = 'SVG',
  EPS = 'EPS',
  PDF = 'PDF',
  XCF = 'XCF',
}

export enum VideoExtension {
  MP4 = 'MP4',
  MKV = 'MKV',
  AVI = 'AVI',
  MOV = 'MOV',
  WMV = 'WMV',
  FLV = 'FLV',
  WEBM = 'WEBM',
  M4V = 'M4V',
  MPG = 'MPG',
  MPEG = 'MPEG',
  GP3 = 'GP3',
  G2P3 = 'G2P3',
  OGV = 'OGV',
  SWF = 'SWF',
  VOB = 'VOB',
  RM = 'RM',
  RMVB = 'RMVB',
  TS = 'TS',
}

export const getFileType = (extension: string | undefined): FileType => {
  if (!extension) return FileType.Unsupported;
  if (extension in ImageExtension) return FileType.Image;
  if (extension in VideoExtension) return FileType.Video;
  return FileType.Unsupported;
};

type MediaExtensions = ImageExtension | VideoExtension;

type FileInfo = {
  fileExtension: MediaExtensions;
  fileType: FileType;
};
export const getFileTypeExtension = (extension: string | undefined): FileInfo | undefined => {
  if (!extension) return undefined; // Return undefined if the extension is empty or undefined

  const normalizedExtension = extension.toUpperCase() as MediaExtensions;

  if (Object.values(VideoExtension).includes(normalizedExtension as VideoExtension)) {
    return {
      fileExtension: normalizedExtension as VideoExtension,
      fileType: FileType.Video,
    };
  }

  if (Object.values(ImageExtension).includes(normalizedExtension as ImageExtension)) {
    return {
      fileExtension: normalizedExtension as ImageExtension,
      fileType: FileType.Image,
    };
  }

  return undefined;
};
